import { Row, Col, Button, Form, Modal, Table } from "react-bootstrap";
import Base from "./base.js";
import React, { useState, useRef, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { gql, useQuery, useMutation, useLazyQuery } from "@apollo/client";
import UpcomingAppointmentCard from "../component/upcomingAppointmentCard";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  success_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  getPermissionForAction,
  getForamttedTime,
  getDay,
  getDate,
  concatAllErrors,
} from "../Common/helpers";
import PaymentDetail from "component/AppointmentDetails/paymentDetail";
import RefundRequestDetail from "component/AppointmentDetails/refundRequestDetail";
import Preloader from "../Common/Preloder/Preloader";
import { Formik } from "formik";
import { dataPatientInVar, isCustomAppVar } from "../cache/cache.js";
import * as Sentry from "@sentry/browser";

function AppointmentDetail(props) {
  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const identifier = props.match.params.appointmentId;
  const [shouldBeSeen, setShouldBeSeen] = useState(null);
  const [enableEdit, setEnableEdit] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [hold, setHold] = useState(false);
  const formikRef = useRef();
  const { history } = props;

  const [hideRefundButton, setHideRefundButton] = useState(false);

  const REQUEST_EVENT = gql`
  query {
    event(id:"${identifier}"){
      title
      description
      start
      end
      id
      eventId
      duration
      discount
      status
      price
      displayStatus
      initialDate
      lastDate
      requirePayment
      identifier
      hasRefund
      refundRequests {
        edges{
          node {
            id
            amount
            status
            displayStatus
            notes
            dateRefunded
            refundId
            created
          }
        }
      } 
      eventType{
        title
        id
      }
      waitinglist{
        waitingList{
          name
          id
          isUrgent
        }
        lastSeenDate
        shouldBeSeenStart
        shouldBeSeenEnd
        calledDate
        waitingListNotes
      }
      doctorEventType{
        title
        id
        duration
        buffer
      }
      paymentSources{
        edges{
            node {
              id
              amountRefunded
              reference
              balance
              status
              paymentLink
              amountAllocated
              linkMessage
              smsSent
            }
        }
      }
      location{
        title
      }
      patient{
        firstName
        lastName
        phone
        age
        identifier
        displayGender
        id
        identifier
        discount
        doctor{
          identifier
        }
        patientId
      }
      doctor{
        firstName
        lastName  
        email
        phone
        id
        identifier
        overrideSchedules{
          edges {
            node {
              date
              shifts{
                edges {
                  node {
                    startTime
                    endTime
                    id
                  }
                }
              } 
            }
          }
        }
        availability{
          edges{
            node{
              day
              id
              shifts{
                edges{
                  node{
                    endTime
                    id
                    startTime
                  }
                }
              }
            }
          }
        }
        eventType {
          edges {
            node {
              id
              title
              buffer
              duration
              price
              notification{
                downPaymentNotification
                downPaymentNotificationCutoff
              }
              eventLocation {
                id
                title
              }
              eventType {
                id
                title
              }
            }
          }
        }  
         
      }
      
    }
  }
`;

  const CANCEL_APPOINTMENT = gql`
    mutation cancelEvent($id: ID!, $hold: Boolean!) {
      cancelEvent(id: $id, hold: $hold) {
        obj {
          status
          id
          start
          holdWaitingReplacement
          doctor {
            identifier
          }
        }
      }
    }
  `;

  const DELETE_APPOINTMENT = gql`
    mutation deleteEvent($id: ID!) {
      deleteEvent(id: $id) {
        deleted
      }
    }
  `;

  const CHECKEDIN_STATUS_APPOINTMENT = gql`
    mutation checkedInStatusEvent($id: ID!) {
      checkedInStatusEvent(id: $id) {
        obj {
          status
          id
        }
      }
    }
  `;

  const NO_SHOW_STATUS = gql`
    mutation noshowStatusEvent($id: ID!) {
      noshowStatusEvent(id: $id) {
        obj {
          status
          id
        }
      }
    }
  `;

  const REFUND_APPOINTMENT = gql`
    mutation refundRequest($id: ID!) {
      refundRequest(id: $id) {
        error
        obj {
          status
          id
          hasRefund
          paymentSources {
            edges {
              node {
                status
                amountDebited
                amountRefunded
                sourceType {
                  name
                }
              }
            }
          }
        }
      }
    }
  `;

  const UPDATE_APPOINTMENT = gql`
    mutation updatePartialEvent(
      $id: ID!
      $requirePayment: Boolean!
      $initialDate: Date
      $description: String!
      $title: String!
      $waitinglist: ID
    ) {
      updatePartialEvent(
        input: {
          id: $id
          requirePayment: $requirePayment
          initialDate: $initialDate
          description: $description
          title: $title
          waitinglist: $waitinglist
        }
      ) {
        errors {
          field
          messages
        }
        obj {
          start
          end
          title
          description
          id
          eventType {
            title
            id
          }
          location {
            title
            id
          }
          doctor {
            id
            firstName
            lastName
          }
          duration
          price
        }
      }
    }
  `;

  const WAITING_LISTS = gql`
    query ($doctor_identifier: String!) {
      listWaitinglist(doctor_Identifier: $doctor_identifier) {
        edges {
          node {
            id
            name
            priority
          }
        }
      }
    }
  `;

  const [getEvent, { data, loading }] = useLazyQuery(REQUEST_EVENT, {
    fetchPolicy: "network-only",
    onError: (err) => {
      Sentry.setContext("error", err?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: err });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: err?.networkError?.statusCode,
      });
      Sentry.captureException("REQUEST_EVENT error " + err);

      let errorMsg = concatAllErrors(err?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  useEffect(() => {
    getEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const appointment_detail = data ? data.event : null;

  useEffect(() => {
    if (appointment_detail && appointment_detail.initialDate) {
      setShouldBeSeen(new Date(appointment_detail.initialDate));
    }
  }, [appointment_detail]);

  const [cancelEvent] = useMutation(CANCEL_APPOINTMENT, {
    onCompleted: ({ cancelEvent }) => {
      if (cancelEvent.obj && cancelEvent.obj.status === "CANCELLED") {
        openSnackbarSuccess("Appointment Cancelled", [SNACK_DURATION]);
        setShowAlert(false);
        let docParams = "";
        if (cancelEvent?.obj?.doctor?.identifier) {
          docParams = "&&doctor=" + cancelEvent?.obj?.doctor?.identifier;
        }
        history.push({
          pathname: "/appointments",
          search:
            "?calendarInitDate=" +
            cancelEvent.obj.start.split("+")[0] +
            "&&calendarInitView=timeGridDay" +
            docParams,
        });
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      Sentry.captureException("cancelEvent error " + e);

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const [deleteEvent] = useMutation(DELETE_APPOINTMENT, {
    onCompleted: ({ deleteEvent }) => {
      if (deleteEvent.deleted) {
        openSnackbarSuccess("Appointment Deleted", [SNACK_DURATION]);
        setShowAlert(false);
        history.goBack();
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      Sentry.captureException("deleteEvent error " + e);
      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const [checkedInStatusAppointment] = useMutation(
    CHECKEDIN_STATUS_APPOINTMENT,
    {
      onCompleted: ({ checkedInStatusEvent }) => {
        if (
          checkedInStatusEvent.obj &&
          checkedInStatusEvent.obj.status === "CHECKEDIN"
        ) {
          openSnackbarSuccess("Patient CheckedIn", [SNACK_DURATION]);
        }
      },
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        Sentry.captureException("checkedInStatusAppointment error " + e);

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const [noStatusApi] = useMutation(NO_SHOW_STATUS, {
    onCompleted: ({ noshowStatusEvent }) => {
      if (noshowStatusEvent.obj && noshowStatusEvent.obj.status === "NO_SHOW") {
        openSnackbarSuccess("Patient no show", [SNACK_DURATION]);
      } else {
        openSnackbarSuccess("Patient confirmed", [SNACK_DURATION]);
      }
      getEvent();
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const [updatePartialEvent] = useMutation(UPDATE_APPOINTMENT, {
    onCompleted: ({ updatePartialEvent }) => {
      openSnackbarSuccess("Appointment Detail Updated", [SNACK_DURATION]);
      setEnableEdit(false);
    },
    refetchQueries: [{ query: REQUEST_EVENT, fetchPolicy: "network-only" }],
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      Sentry.captureException("UPDATE_APPOINTMENT error " + e);

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const [refundRequest] = useMutation(REFUND_APPOINTMENT, {
    onCompleted: ({ refundRequest }) => {
      openSnackbarSuccess("Appointment Refund requested", [SNACK_DURATION]);
      if (appointment_detail?.patient?.identifier) {
        history.push(
          "/patient/record/" + appointment_detail?.patient?.identifier
        );
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      Sentry.captureException("REFUND_APPOINTMENT error " + e);

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const [getWaitingList, { data: waitingListObj = null }] = useLazyQuery(
    WAITING_LISTS,
    {
      fetchPolicy: "network-only",
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        Sentry.captureException("WAITING_LISTS error " + e);

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const cancelAppointment = () => {
    setShowAlert(true);
  };

  const onSubmit = (values, { resetForm }) => {
    let variables = values;
    let appDetDesc = values?.description
      ? values?.description
      : appointment_detail?.description;
    if (!appDetDesc) {
      appDetDesc =
        appointment_detail?.patient?.firstName +
        " " +
        appointment_detail?.patient?.lastName;
    }
    variables["id"] = appointment_detail.id;
    variables["title"] = appointment_detail?.title;
    variables["description"] = appDetDesc;
    if (shouldBeSeen) {
      variables["initialDate"] = shouldBeSeen.toISOString().split("T")[0];
    }
    updatePartialEvent({ variables: variables });
  };

  const initialValues = {
    requirePayment: appointment_detail
      ? appointment_detail.requirePayment
      : true,
    description: appointment_detail ? appointment_detail.description : "",
  };

  const cancelAppointmentClicked = () => {
    cancelEvent({
      variables: { id: appointment_detail.id, hold: hold },
    });
  };

  const deleteAppointmentClicked = () => {
    if (appointment_detail?.id) {
      deleteEvent({
        variables: { id: appointment_detail.id },
      });
    }
  };

  const onClickToCreateAppointment = (e) => {
    e.preventDefault();
    if (appointment_detail && appointment_detail.patient) {
      dataPatientInVar(appointment_detail.patient);
    }
    isCustomAppVar(false);
    setTimeout(() => {
      history.push({ pathname: "/create/appointment" });
    }, 1000);
  };

  const checkinPatient = () => {
    checkedInStatusAppointment({
      variables: { id: appointment_detail.id },
    });
  };

  const noStatusMethod = () => {
    noStatusApi({
      variables: { id: appointment_detail.id },
    });
  };

  const onRefundAppointment = () => {
    refundRequest({
      variables: { id: appointment_detail.id },
    });
    setHideRefundButton(true);
  };

  const isRefundDisabled = (item) => {
    if (item?.hasRefund) return true;
    else return false;
  };

  const rightChildButtons = () => {
    return (
      <div className="d-flex flex-column">
        {appointment_detail && appointment_detail.status !== "CANCELLED" ? (
          <>
            {appointment_detail.status !== "CHECKEDIN" ? (
              <>
                <Button
                  variant="link"
                  className="danger-color"
                  onClick={cancelAppointment}
                >
                  {appointment_detail?.patient ? (
                    <b> Cancel Appointment </b>
                  ) : (
                    <b> Delete Appointment </b>
                  )}
                </Button>
                <Link
                  className="d-inline-block"
                  onClick={(e) => {
                    e.preventDefault();
                    if (appointment_detail && appointment_detail.patient) {
                      isCustomAppVar(false);
                    } else {
                      isCustomAppVar(true);
                    }
                    setTimeout(() => {
                      history.push({
                        pathname: "/create/appointment",
                        search: appointment_detail?.patient
                          ? "?editID=" + appointment_detail.id
                          : "?isCustom=true&&editID=" + appointment_detail.id,
                      });
                    }, 1000);
                  }}
                  to={{
                    pathname: "/create/appointment",
                    search: appointment_detail?.patient
                      ? ""
                      : "?isCustom=true&&editID=" + appointment_detail.id,
                  }}
                >
                  <Button variant="link" className="">
                    <b> Edit Appointment </b>
                  </Button>
                </Link>
              </>
            ) : null}
            {appointment_detail?.patient ? (
              <>
                {appointment_detail &&
                appointment_detail.status !== "CHECKEDIN" ? (
                  <Button variant="link" onClick={checkinPatient}>
                    {" "}
                    <b> Check In </b>{" "}
                  </Button>
                ) : (
                  <Button variant="link" disabled className="success-color">
                    {" "}
                    <b> Checked In </b>{" "}
                  </Button>
                )}
              </>
            ) : null}
            {appointment_detail?.patient ? (
              <>
                {appointment_detail &&
                (appointment_detail.status === "CONFIRMED" ||
                  appointment_detail.status === "NO_SHOW") ? (
                  <Button variant="link" onClick={noStatusMethod}>
                    <b>
                      {appointment_detail.status === "NO_SHOW"
                        ? "Undo no show"
                        : "No show"}
                    </b>
                  </Button>
                ) : (
                  <Button variant="link" disabled className="success-color">
                    <b> Checked In </b>
                  </Button>
                )}
              </>
            ) : null}
          </>
        ) : (
          <>
            <Button variant="link" disabled className="danger-color">
              {" "}
              <b> Appointment Cancelled </b>{" "}
            </Button>

            {/* {appointment_detail?.hasRefund && hideRefundButton != true ? <Button variant="link" onClick={onRefundAppointment} disabled={isRefundDisabled(appointment_detail)}> <b> Refund </b> </Button>
          : null} */}
            {isRefundDisabled(appointment_detail) ? (
              <Button variant="link" onClick={onRefundAppointment}>
                {" "}
                <b> Refund </b>{" "}
              </Button>
            ) : null}
          </>
        )}
      </div>
    );
  };

  const title = appointment_detail ? " / " + appointment_detail.identifier : "";
  let startDate = new Date(appointment_detail?.start);
  let endDate = new Date(appointment_detail?.end);

  useEffect(() => {
    if (appointment_detail?.doctor) {
      if (appointment_detail?.doctor) {
        getWaitingList({
          variables: {
            doctor_identifier: appointment_detail?.doctor.identifier,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment_detail?.doctor]);

  return (
    <Base
      title={"Appointment Detail" + title}
      showHeader={true}
      back="/patients"
      rightChild={rightChildButtons()}
    >
      {loading ? (
        <Preloader />
      ) : (
        <>
          {appointment_detail?.patient ? (
            <>
              <Row className="appointment-detail-info">
                <Col md={6}>
                  <Link
                    className="d-inline-block mb-2"
                    to="/create/appointment"
                    onClick={onClickToCreateAppointment}
                  >
                    <Button variant="link" className="p-0">
                      <i
                        className="fa fa-plus-circle mr-2"
                        aria-hidden="true"
                      ></i>{" "}
                      Create new Appointment
                    </Button>
                  </Link>
                </Col>
                <Col md={6}></Col>
                <Col md={6}>
                  <h6>
                    <b> Patient Information </b>
                  </h6>
                  {appointment_detail ? (
                    <div>
                      <div className="d-flex justify-content-between app-detail-first-line">
                        <h6>
                          Name:
                          {appointment_detail.patient.firstName +
                            " " +
                            appointment_detail.patient.lastName}{" "}
                        </h6>
                      </div>
                      <h6>
                        File Number:
                        <Link
                          className="d-inline-block"
                          // target="_blank"
                          to={`/patient/record/${
                            appointment_detail.patient
                              ? appointment_detail.patient.identifier
                              : null
                          }`}
                        >
                          <Button variant="link" className="p-0 mx-1">
                            {appointment_detail.patient.identifier}
                          </Button>
                        </Link>{" "}
                      </h6>
                      {appointment_detail.patient.age ? (
                        <h6> Age: {appointment_detail.patient.age} </h6>
                      ) : null}
                      {appointment_detail.patient.displayGender ? (
                        <h6>
                          {" "}
                          Gender:{" "}
                          <span className="capitalize">
                            {appointment_detail.patient.displayGender.toLowerCase()}{" "}
                          </span>{" "}
                        </h6>
                      ) : null}
                      {appointment_detail.patient.phone ? (
                        <h6> Phone: {appointment_detail.patient.phone}</h6>
                      ) : null}
                      {appointment_detail.discount ? (
                        <h6>
                          {" "}
                          Discount provided:{" "}
                          {appointment_detail.discount
                            ? appointment_detail.discount + "%"
                            : null}
                        </h6>
                      ) : null}
                      {appointment_detail.price ? (
                        <h6> Price: {appointment_detail.price} KWD</h6>
                      ) : null}
                    </div>
                  ) : null}
                </Col>
                <Col md={6} className="d-flex justify-content-start">
                  <div>
                    <h6>
                      <b>Practitioner Information</b>
                    </h6>
                    {appointment_detail ? (
                      <div>
                        <h6>
                          {" "}
                          Name:{" "}
                          {appointment_detail.doctor
                            ? appointment_detail.doctor.firstName +
                              " " +
                              appointment_detail.doctor.lastName
                            : ""}{" "}
                        </h6>
                        {appointment_detail.doctor &&
                        appointment_detail.doctor.email ? (
                          <h6> Email: {appointment_detail.doctor.email} </h6>
                        ) : null}
                        {appointment_detail.doctor &&
                        appointment_detail.doctor.phone ? (
                          <h6> Phone: {appointment_detail.doctor.phone}</h6>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </Col>
              </Row>

              <Row className="my-3">
                <Col md={12}>
                  <Table striped bordered responsive>
                    <thead>
                      <tr>
                        <th>Waiting list name</th>
                        <th>Last seen</th>

                        {appointment_detail?.waitinglist?.waitingList
                          ?.isUrgent && <th>Called date</th>}
                        {appointment_detail?.waitinglist?.waitingList
                          ?.isUrgent === false && (
                          <>
                            <th>Should be seen start</th>
                            <th>Should be seen end</th>
                          </>
                        )}
                        <th>Waiting list notes</th>
                      </tr>
                    </thead>

                    <tbody>
                      {appointment_detail?.waitinglist ? (
                        <tr>
                          <td>
                            {appointment_detail.waitinglist.waitingList.name}
                          </td>
                          <td>
                            {appointment_detail.waitinglist.lastSeenDate
                              ? getDate(
                                  new Date(
                                    appointment_detail.waitinglist.lastSeenDate
                                  )
                                )
                              : "-"}
                          </td>

                          {appointment_detail?.waitinglist?.waitingList
                            ?.isUrgent && (
                            <td>
                              {appointment_detail.waitinglist.calledDate
                                ? getDate(
                                    new Date(
                                      appointment_detail.waitinglist.calledDate
                                    )
                                  )
                                : "-"}
                            </td>
                          )}

                          {appointment_detail?.waitinglist?.waitingList
                            ?.isUrgent === false && (
                            <>
                              <td>
                                {appointment_detail.initialDate
                                  ? getDate(
                                      new Date(appointment_detail.initialDate)
                                    )
                                  : "-"}
                              </td>
                              <td>
                                {appointment_detail.lastDate
                                  ? getDate(
                                      new Date(appointment_detail.lastDate)
                                    )
                                  : "-"}
                              </td>
                            </>
                          )}
                          <td>
                            {appointment_detail?.waitinglist.waitingListNotes ||
                              "-"}
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan={3}>
                            This appointment is not part of waiting list. Do you
                            wish to add it on a waiting list? If so please click
                            "Edit Appointment" to update and include on waiting
                            list.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                innerRef={formikRef}
              >
                {({ handleSubmit, handleChange, values }) => (
                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <Row className="mt-3 pb-4 align-items-end">
                      <Col
                        sm={12}
                        className="d-flex align-items-end "
                        xs={{ order: "first" }}
                        md={{ order: "first" }}
                        lg={4}
                      >
                        <UpcomingAppointmentCard
                          event={appointment_detail}
                          detail={true}
                          extraClasses="w100 mb-0"
                        />
                      </Col>
                      <Col
                        sm={12}
                        md={12}
                        lg={4}
                        className="mt-2"
                        xs={{ order: "last" }}
                      >
                        <h6>
                          <b> Notes </b>
                        </h6>
                        <Form.Control
                          autoComplete="off"
                          as="textarea"
                          rows={5}
                          name="description"
                          disabled={!enableEdit}
                          value={values.description}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col
                        sm={12}
                        md={{ span: 6, order: "first" }}
                        lg={{ order: "last" }}
                      >
                        {getPermissionForAction("appointments", "change") ? (
                          <Row>
                            <Col className=" d-flex justify-content-end">
                              <Button
                                style={{ width: "30px" }}
                                variant="link"
                                type="submit"
                                block
                                className={enableEdit ? "" : " d-none"}
                              >
                                {" "}
                                <b> Save {enableEdit}</b>{" "}
                              </Button>
                              <Button
                                style={{ width: "30px" }}
                                variant="link"
                                type="button"
                                block
                                onClick={() => {
                                  setEnableEdit(true);
                                }}
                                className={enableEdit ? " d-none " : ""}
                              >
                                {" "}
                                <b> Edit {enableEdit}</b>{" "}
                              </Button>
                            </Col>
                          </Row>
                        ) : null}
                        <Form.Group
                          as={Row}
                          className="justify-content-start"
                          style={{ marginBottom: "0rem" }}
                        >
                          <Col xs={8} sm={4} md={12}>
                            <Form.Label>Auto cancellation</Form.Label>
                          </Col>
                          <Col
                            xs={4}
                            sm={8}
                            md={12}
                            className="pst-absolute-130px"
                          >
                            <Form.Check
                              type="checkbox"
                              disabled={!enableEdit}
                              name="requirePayment"
                              onChange={handleChange}
                              value={values.requirePayment}
                              checked={values.requirePayment}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
              <Row>
                <Modal show={showAlert} onHide={() => setShowAlert(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title>Cancel Appointment</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Cancel Appointment for{" "}
                    {appointment_detail
                      ? appointment_detail.patient.firstName +
                        " " +
                        appointment_detail.patient.lastName
                      : " Patient "}
                    ?
                    <div className="d-flex">
                      <Form.Check
                        className=""
                        type="checkbox"
                        value={hold}
                        checked={hold}
                        onChange={() => setHold(!hold)}
                      />
                      Hold the Slot
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => setShowAlert(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      onClick={cancelAppointmentClicked}
                    >
                      Submit
                    </Button>
                  </Modal.Footer>
                </Modal>
                {appointment_detail ? (
                  <Col sm={12} md={12}>
                    <PaymentDetail event={appointment_detail} />
                    {appointment_detail.refundRequests.edges.length > 0 ? (
                      <RefundRequestDetail
                        event={appointment_detail}
                        REQUEST_EVENT={REQUEST_EVENT}
                      />
                    ) : null}
                  </Col>
                ) : null}
              </Row>
            </>
          ) : (
            <>
              <Row className="mt-5">
                <Col md={6} className="d-flex justify-content-start">
                  <h6 className="text-capitalize">
                    <b>{appointment_detail?.title}</b>
                  </h6>
                </Col>
                <Col md={6} className="d-flex justify-content-start">
                  <div>
                    <h6>
                      <b>Practitioner Information</b>
                    </h6>
                    {appointment_detail ? (
                      <div>
                        <h6>
                          {" "}
                          Name:{" "}
                          {appointment_detail.doctor
                            ? appointment_detail.doctor.firstName +
                              " " +
                              appointment_detail.doctor.lastName
                            : ""}{" "}
                        </h6>
                        {appointment_detail.doctor &&
                        appointment_detail.doctor.email ? (
                          <h6> Email: {appointment_detail.doctor.email} </h6>
                        ) : null}
                        {appointment_detail.doctor &&
                        appointment_detail.doctor.phone ? (
                          <h6> Phone: {appointment_detail.doctor.phone}</h6>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </Col>
                <Col md={6} className="d-flex justify-content-start">
                  <div>
                    <h6>
                      <b>Appointment Information</b>
                    </h6>
                    {appointment_detail ? (
                      <div>
                        <span> {getDay(startDate.getDay())} </span>
                        <span>{getDate(startDate)} </span>
                        <div>
                          {" "}
                          Duration: {appointment_detail.duration} Minutes{" "}
                        </div>
                        <div>From: {getForamttedTime(startDate)}</div>
                        <div>To: {getForamttedTime(endDate)}</div>
                      </div>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Modal show={showAlert} onHide={() => setShowAlert(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>Delete Appointment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Delete {appointment_detail?.title}?
                  {/* <div className="d-flex">
                  <Form.Check
                    className=""
                    type="checkbox"
                    value={hold}
                    checked={hold}
                    onChange={() => setHold(!hold)}
                  />
                  Hold the Slot
                </div> */}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowAlert(false)}
                  >
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={deleteAppointmentClicked}>
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </>
      )}
    </Base>
  );
}
export default withRouter(AppointmentDetail);
